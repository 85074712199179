import { Col, Row } from "antd";
import React from "react";
// import { MenuOutlined, SearchOutlined } from "@ant-design/icons"
import styles from "./index.module.less";

// 在最外层使用 span 将会导致排版灾难，因原作者书写的代码中大量应用了 relative 样式
// 此处将每个文字区域的占用宽度抽象出来，以供全局调整
const textSpan = 12;

const WebsiteProfile: React.FunctionComponent = () => (
  <Row className={styles.sizeBody}>
    <Col>
      <Row className={styles.fieldOne} />
      <Row className={styles.legalTop} justify="center">
        <Col span={textSpan}>
          <Row align="middle" justify="start" className={styles.fieldTwo}>
            <Col>
              我们始终追求品质服务与良好客户体验，致力于打造一套精品律师事务所的服务体系，为客户提供便捷、优质的专业法律服务。
            </Col>
          </Row>
          <Row className={styles.underlyingLegalService}>Legal Service</Row>
          <Row align="middle" justify="start" className={styles.fieldTwo}>
            我们始终秉承团队合作的理念，以客户权益为先的原则，顺利办理了各类疑难案件以及各类复杂非诉项目，在法律服务市场建立了优良口碑。
          </Row>
        </Col>
      </Row>

      <Row className={styles.layer1997}>
        <Col>
          <Row className={styles.upperFile}>
            <Col>
              <Row className={styles.underlying1997}>1997</Row>
              <Row className={styles.left1997Found}>
                <Col>
                  <span className={styles.text1997}>1997&nbsp;</span>
                  <span className={styles.textFound}>&nbsp;Founded</span>
                </Col>
              </Row>
              <Row className={styles.fieldTwo} align="middle" justify="center">
                <Col span={textSpan}>
                  历经二十余年耕耘探索及近年来转型发展，现已成为一家传统与新型管理模式相结合的综合性律师事务所。我们始终追求品质服务与良好客户体验，致力于打造一套精品律师事务所的服务体系，为客户提供便捷、优质的专业法律服务。
                  <br />
                  <br />
                  上海市弘正律师事务所现有执业律师八名，大多数拥有15年以上的律师执业经历，尤其以争议解决、金融资本及债务重组等领域的法律服务见长。我们始终秉承团队合作的理念，以客户权益为先的原则，顺利办理了各类疑难案件以及各类复杂非诉项目，在法律服务市场建立了优良口碑。
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  </Row>
);
export default WebsiteProfile;
