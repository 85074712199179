import { Col, Row } from "antd";
import React from "react";
import Logo from "src/assets/homePage/Logo.png";
import styles from "./index.module.less";

const Header: React.FunctionComponent = () => (
  <div>
    <Row align="middle" justify="space-between">
      <Col span={12}>
        <Row align="middle" justify="start" wrap={false}>
          <Col>
            <img src={Logo} />
          </Col>
          <Col>
            <span className={styles.fontSizeStyle}>上海弘正律师事务所</span>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
);
export default Header;
