import { Col, Row, Typography } from "antd";
import React from "react";
import styles from "./index.module.less";

const Footer: React.FunctionComponent = () => (
  <Row className={styles.footer} align="middle" justify="center">
    <Col>
      <Row align="middle" justify="center">
        <Typography.Text className={styles.footerText}>
          © 2022 上海弘正律师事务所
        </Typography.Text>
      </Row>
      <Row align="middle" justify="center">
        <Typography.Link
          href="http://beian.miit.gov.cn"
          target="_blank"
          className={styles.footerText}
          // FIXME: only inline style work for `Link` component
          style={{ color: "white" }}
        >
          沪ICP备2021036070号-1
        </Typography.Link>
      </Row>
    </Col>
  </Row>
);
export default Footer;
