import { Carousel, Col, Row } from "antd";
// import { Header } from 'antd/lib/layout/layout'
import React from "react";
import Shuffling1 from "src/assets/homePage/shuffling1.png";
import Shuffling2 from "src/assets/homePage/shuffling2.png";
import Shuffling3 from "src/assets/homePage/shuffling3.png";
import Shuffling4 from "src/assets/homePage/shuffling4.png";
import Shuffling5 from "src/assets/homePage/shuffling5.png";
import Header from "../Header";
import styles from "./index.module.less";

const ShufflingFigure: React.FunctionComponent = () => {
  return (
    <div className={styles.banner}>
      <div className={styles.header}>
        <div className={styles.headerBottom}>
          <Header />
        </div>
        <Row align="middle" justify="center">
          <Col className={styles.sizeCenter}>
            <Row justify="center">我们曾经见证历史</Row>
            <Row justify="center">也致力于在最前沿探索和创造未来</Row>
          </Col>
        </Row>
      </div>

      <div className={styles.background}>
        <Carousel
          autoplaySpeed={5000}
          dots={false}
          pauseOnHover={false}
          autoplay
        >
          <div className={styles.contentStyle}>
            <img src={Shuffling1} />
          </div>
          <div className={styles.contentStyle}>
            <img src={Shuffling2} />
          </div>
          <div className={styles.contentStyle}>
            <img src={Shuffling5} />
          </div>
          <div className={styles.contentStyle}>
            <img src={Shuffling4} />
          </div>
          <div className={styles.contentStyle}>
            <img src={Shuffling3} />
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default ShufflingFigure;
