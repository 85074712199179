// import { Carousel } from 'antd'
import React from "react";
import WebsiteProfile from "./components/Home/Body";
// import styles from './app.module.less'
import Shuffling from "./components/Home/Shuffling/index";
import Footer from "./components/Footer";

const App: React.FunctionComponent = () => {
  return (
    <div>
      <Shuffling />
      <WebsiteProfile />
      <Footer />
    </div>
  );
};

export default App;
